import React, { useMemo, useRef } from "react";
import { Box, FormHelperText, InputBase, InputLabel } from "@mui/material";
import styled from "@emotion/styled";
import { errorColor, primaryColor, silverSageColor } from "../../assets/colors";
// --------------------------------------------------------------------------------
const CustomOtpInput = ({
  label,
  length = 6,
  value,
  onComplete,
  helperText = "\u200C",
  error,
  disabled,
  containerProps,
  ...otherProps
}) => {
  const otpValues = useMemo(() => {
    const valueArray = value.split("");
    const items = Array.from({ length }, (_, index) =>
      /^\d*$/.test(valueArray[index]) ? valueArray[index] : ""
    );
    return items;
  }, [value, length]);

  const inputRefs = useRef([]);

  const handleInputChange = (value, index) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newValues = [...otpValues];
      newValues[index] = value;

      if (index < length - 1 && value !== "") {
        inputRefs.current[index + 1].focus();
      }

      onComplete(newValues.join(""));
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior

    const pastedText = event.clipboardData.getData("Text").replace(/\D/g, "");

    const newValues = Array.from({ length }, (_, index) => {
      const char = pastedText.charAt(index) || ""; // Use empty string if the pastedText is shorter

      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }

      return char;
    });

    onComplete(newValues.join(""));

    const lastInputIndex = Math.min(pastedText.length, length - 1);
    inputRefs.current[lastInputIndex].focus();
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && otpValues[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Box {...containerProps}>
      <InputLabel
        shrink
        sx={{
          color: "#33383D",
          "&.Mui-focused, &.Mui-error": { color: "unset" },
          transform: "none",
          fontWeight: 600,
          fontSize: { xs: 14, lg: 16 },
          mb: 1.25,
        }}
      >
        {label}
      </InputLabel>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        {otpValues.map((value, index) => (
          <BootstrapInput
            key={index}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            variant="outlined"
            type="tel"
            autoFocus={index === 0}
            value={value}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeyDown(e, index)}
            error={error}
            sx={{
              mr: 0 === index ? 0 : 2,
              ...otherProps.sx,
            }}
            disabled={disabled}
          />
        ))}
      </Box>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};

export default CustomOtpInput;

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "&.MuiInputBase-root": {
    // backgroundColor: inputColor,
    border: `1.5px solid ${silverSageColor}`,
    borderRadius: 12,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    padding: 0,
    width: 56,
    height: 56,
    "&.Mui-disabled": {
      opacity: 0.3,
    },
    "&.Mui-focused": {
      borderColor: primaryColor,
      boxShadow: "0px 0px 0px 2px rgba(65, 173, 206, 0.11)",
    },
    "&.Mui-error": {
      borderColor: errorColor,
      boxShadow: "none",
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    fontSize: 25,
    textAlign: "center",
    fontWeight: 900,
  },
  "& + p": {
    transition: theme.transitions.create(["opacity"]),
    opacity: 0,
    "&.Mui-error": {
      opacity: 1,
    },
  },
}));
