import platform from "platform";

export const drawerWidth = 300;
export const appBarHeight = 65;

export const isProduction = process.env.REACT_APP_ENV === "production";

export const defaultMaxLength = 25;
export const emailMaxLength = 35;

export const adminUrl = "https://adminapi.yekpay.com/";
export const baseUerl = "https://api.yekpay.com/";

export const fullHeight = platform.os.family === "Android" ? "100vh" : "100dvh";
