import { createElement } from "react";
import { Box, Typography } from "@mui/material";
import {
  ceruleanBlueColor,
  coralRedColor,
  dodgerBlueColor,
  graphiteGrayColor,
  mintGreenColor,
  royalBlueColor,
  sereneSkyBlueColor,
} from "../assets/colors";
import { NON_DIGIT_REGEX } from "./regex";

export const formatTime = (counter) => {
  let seconds = (counter % 60).toString();
  let minutes = Math.floor(counter / 60).toString();
  minutes = minutes.length == 1 ? "0" + minutes : minutes;
  seconds = seconds.length == 1 ? "0" + seconds : seconds;
  return minutes + ":" + seconds;
};

export const maskPhoneNumber = (phoneNumber) => {
  // Use a regular expression to match the first and last 3 digits and replace them with asterisks
  const maskedPhoneNumber = phoneNumber.replace(
    /^(\d{3})\d+(\d{3})$/,
    "$1***$2"
  );
  return maskedPhoneNumber;
};

export const camelCaseToTitleCase = (input) => {
  // Return input if it is null or undefined
  if (input == null) {
    return input;
  }

  // Split the input string into words
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first letter of each word and join them back together
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the title case words to form the final title case string
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
};

export const pascalCaseToTitleCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3");
};

export const pascalCaseToKebabCase = (str) => {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

export const formatCardNumber = (input) => {
  // Use regular expressions to insert spaces between every four characters
  const formattedNumber = input ? input.replace(/(.{4})/g, "$1 ") : "";

  return formattedNumber;
};

export const maskCreditCardNumber = (cardNumber) => {
  // Convert the number to a string for easier manipulation
  const cardNumberStr = cardNumber.toString();

  // Extract the first 4 digits and the last 4 digits
  const firstFourDigits = cardNumberStr.substring(0, 4);
  const lastFourDigits = cardNumberStr.substring(12);

  // Create the masked card number
  const maskedCardNumber = `${firstFourDigits}********${lastFourDigits}`;

  return maskedCardNumber;
};

export const addBalanceSeparator = (balanceWithoutSeparator) => {
  const balanceString = balanceWithoutSeparator.toString();
  // Use regular expression to add separators (, in this case) every three digits from the right
  return balanceString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeBalanceSeparator = (balanceWithSeparator) => {
  // Use regular expression to replace all separators (, in this case)
  // g flag stands for global, which replaces all occurrences
  return balanceWithSeparator.replace(/,/g, "");
};

// export const convertYYMMtoMMYY = (yymm) => {
//   // Ensure the input is a string
//   if (!yymm) return "";
//   // Check if the input has at least 4 characters
//   if (String(yymm).length < 4) {
//     return i18n.t("invalidInput");
//   }

//   // Extract the month and year from the input string
//   var year = yymm.slice(0, 2);
//   var month = yymm.slice(2);

//   // Add leading zeros to the month if needed
//   if (month.length === 1) {
//     month = "0" + month;
//   }

//   // Combine the month and year in "MM/YY" format
//   var mmYY = month + " / " + year;

//   return mmYY;
// };

// export const renderCurrencyIcon = (currencyCode) => {
//   let icon = "div";
//   switch (currencyCode) {
//     case "EUR":
//       icon = EuroIcon;
//       break;
//     case "USD":
//       icon = DollarIcon;
//       break;
//     case "TRY":
//       icon = LiraIcon;
//       break;
//     case "GBP":
//       icon = PoundIcon;
//       break;
//     case "RUB":
//       icon = RubleIcon;
//       break;
//     default:
//       break;
//   }
//   return icon;
// };

// export const renderCurrencyCircleIcon = (currencyCode) => {
//   let icon = "div";
//   switch (currencyCode) {
//     case "EUR":
//       icon = EuroCircleIcon;
//       break;
//     case "USD":
//       icon = DollarCircleIcon;
//       break;
//     case "TRY":
//       icon = LiraCircleIcon;
//       break;
//     case "GBP":
//       icon = PoundCircleIcon;
//       break;
//     case "RUB":
//       icon = RubleCircleIcon;
//       break;
//     default:
//       break;
//   }
//   return icon;
// };

export const renderCurrencyColor = (currencyCode) => {
  let currencyColor = null;
  switch (currencyCode) {
    case "EUR":
      currencyColor = ceruleanBlueColor;
      break;
    case "USD":
      currencyColor = mintGreenColor;
      break;
    case "TRY":
      currencyColor = coralRedColor;
      break;
    case "RUB":
      currencyColor = dodgerBlueColor;
      break;
    case "GBP":
      currencyColor = royalBlueColor;
      break;
  }
  return currencyColor;
};

// export const renderEventIcon = ({ event, from, to }) => {
//   let eventIcon = (
//     <DollarCircleOutlineIcon color={graphiteGrayColor} width={20} />
//   );
//   switch (event) {
//     case "Activate Card":
//       eventIcon = (
//         <Typography
//           sx={{ fontSize: 14, fontWeight: 500, position: "relative" }}
//         >
//           P
//           <Typography
//             sx={{
//               fontSize: 11,
//               fontWeight: 500,
//               position: "absolute",
//               top: -5,
//               right: -6.5,
//             }}
//           >
//             +
//           </Typography>
//         </Typography>
//       );
//       break;
//     case "Create Virtual Card":
//       eventIcon = (
//         <Typography
//           sx={{ fontSize: 14, fontWeight: 500, position: "relative" }}
//         >
//           V
//           <Typography
//             sx={{
//               fontSize: 11,
//               fontWeight: 500,
//               position: "absolute",
//               top: -5,
//               right: -6.5,
//             }}
//           >
//             +
//           </Typography>
//         </Typography>
//       );
//       break;
//     case "Topup":
//       eventIcon = <AddCircleOitlineIcon color={graphiteGrayColor} width={20} />;
//       break;
//     case "Exchange":
//       eventIcon = (
//         <Box sx={{ position: "relative" }}>
//           {createElement(renderCurrencyIcon(from), { width: 20 })}
//           <Box
//             sx={{
//               bgcolor: sereneSkyBlueColor,
//               position: "absolute",
//               bottom: -15,
//               right: -20,
//               borderRadius: 1,
//               height: 20,
//               width: 20,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {createElement(renderCurrencyIcon(to), { width: 12 })}
//           </Box>
//         </Box>
//       );
//       break;
//     default:
//       break;
//   }
//   return eventIcon;
// };

// export const renderTransactionTitle = (transaction) => {
//   switch (transaction.event) {
//     case "Activate Card":
//       return (
//         <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }}>
//           {i18n.t("addingPhysicalCard")}
//         </Typography>
//       );
//     case "Create Virtual Card":
//       return (
//         <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }}>
//           {i18n.t("addingVirtualCards")}
//         </Typography>
//       );
//     case "Topup":
//       return (
//         <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }}>
//           {transaction.event}{" "}
//           <Typography
//             sx={{
//               display: "inline-block",
//               color: renderCurrencyColor(transaction.currency),
//               fontSize: 14,
//               fontWeight: 500,
//             }}
//           >
//             {transaction.currency}
//           </Typography>{" "}
//         </Typography>
//       );
//     case "Topup Card":
//       return (
//         <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }}>
//           {transaction.event}{" "}
//           <Typography
//             sx={{
//               display: "inline-block",
//               color: renderCurrencyColor(transaction.currency),
//               fontSize: 14,
//               fontWeight: 500,
//             }}
//           >
//             {transaction.currency}
//           </Typography>{" "}
//         </Typography>
//       );
//     case "Exchange":
//       return (
//         <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }}>
//           {transaction.event}{" "}
//           <Typography
//             sx={{
//               display: "inline-block",
//               color: renderCurrencyColor(transaction.from),
//               fontSize: 14,
//               fontWeight: 500,
//             }}
//           >
//             {transaction.from}
//           </Typography>{" "}
//           {i18n.t("to")}{" "}
//           <Typography
//             sx={{
//               display: "inline-block",
//               color: renderCurrencyColor(transaction.to),
//               fontSize: 14,
//               fontWeight: 500,
//             }}
//           >
//             {transaction.to}
//           </Typography>
//         </Typography>
//       );
//     default:
//       return <></>;
//   }
// };

export const splitIBAN = (iban) => {
  // Check if IBAN has a valid country code (assuming a 2-letter code)
  const countryCode = iban.slice(0, 2);
  const isValidCountryCode = /^[A-Z]{2}$/.test(countryCode);

  if (isValidCountryCode) {
    // IBAN format: [2-letter country code][2-digit check digits][rest of the number]
    const number = iban.slice(2); // Assuming the country code is 2 characters
    return {
      countryCode,
      number,
    };
  } else {
    // If no valid country code, consider the entire IBAN as the number
    return {
      number: iban,
    };
  }
};

export const formatMonthYear = (input) => {
  // Extract month and year from the input string
  const month = input.substring(2);
  const year = input.substring(0, 2);

  // Format the output as "MM/YY"
  const formattedDate = `${month}/${year}`;

  return formattedDate;
};

export const removeLeadingZero = (mobileNumber) => {
  // Check if the input is a valid mobile number
  if (typeof mobileNumber !== "string" || !/^\d+$/.test(mobileNumber)) {
    console.error("Invalid mobile number format");
    return mobileNumber;
  }

  // Remove leading zero
  return mobileNumber.replace(/^0+/, "");
};

export const toFixedWithoutZeros = (num, precision = 2) =>
  Number(num).toFixed(precision).replace(/\.0+$/, "");

export const getDirection = (text) => {
  const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlChars.test(text) ? "ltr" : "rtl";
};

export const numberToPersianWords = (number) => {
  const persianNumbers = [
    "",
    "یک",
    "دو",
    "سه",
    "چهار",
    "پنج",
    "شش",
    "هفت",
    "هشت",
    "نه",
    "ده",
    "یازده",
    "دوازده",
    "سیزده",
    "چهارده",
    "پانزده",
    "شانزده",
    "هفده",
    "هجده",
    "نوزده",
  ];

  const tens = [
    "",
    "",
    "بیست",
    "سی",
    "چهل",
    "پنجاه",
    "شصت",
    "هفتاد",
    "هشتاد",
    "نود",
  ];
  const hundreds = [
    "",
    "صد",
    "دویست",
    "سیصد",
    "چهارصد",
    "پانصد",
    "ششصد",
    "هفتصد",
    "هشتصد",
    "نهصد",
  ];
  const thousands = ["", "هزار", "میلیون", "میلیارد"];

  if (number === 0) return "صفر";

  let words = "";
  let unit = 0;

  while (number > 0) {
    let chunk = number % 1000;
    if (chunk) {
      let chunkWords = "";

      if (chunk > 99) {
        chunkWords += hundreds[Math.floor(chunk / 100)] + " و ";
        chunk %= 100;
      }

      if (chunk > 19) {
        chunkWords += tens[Math.floor(chunk / 10)] + " و ";
        chunk %= 10;
      }

      if (chunk > 0) {
        chunkWords += persianNumbers[chunk] + " و ";
      }

      words = chunkWords.slice(0, -3) + " " + thousands[unit] + " " + words;
    }

    number = Math.floor(number / 1000);
    unit++;
  }

  return words.trim();
};

export const numberWithCommas = (x) => {
  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
