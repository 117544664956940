export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const NON_ENGLISH_REGEX = /[^\x00-\x7F]+/gi;
export const NON_ALPHABETIC_REGEX = /[^A-Za-z\s]/g;
export const NON_DIGIT_REGEX = /\D/g;

export const UPPERCASE_LETTERS_REGEX = /[A-Z]+/;
export const LOWERCASE_LETTERS_REGEX = /[a-z]+/;
export const DIGIT_REGEX = /[0-9]+/;
export const SPECIAL_CHARACTERS_REGEX = /[^A-Za-z0-9]+/;

export const URL_REGEX =
  /\b(?:ftp:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:\/\S*)?\b/;

export const MOBILE_NUMBER_REGEX =
  /^(\+?\d{1,4}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d\s.-]{7,14}$/;

export const NON_PERSIAN_REGEX =
  /[^\u0600-\u06FF\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFF\s]+/;

export const IP_REGEX =
  /\b((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\b/;

export const NON_ENGLISH_ALPHANUMERIC = /[^A-Za-z\d\s]/g;
