import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const registerUserAPI = ({
  firstname,
  lastname,
  mobile,
  promotionCode,
}) => {
  let postData = {
    first_name: firstname,
    last_name: lastname,
    promotion_code: promotionCode,
    mobile: mobile.startsWith("0") ? mobile.substring(1) : mobile,
  };
  return axiosMiddleware({
    method: "post",
    url: "register",
    data: postData,
  });
};
export const checkEmailAPI = ({ email }) => {
  let postData = {
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "check-email",
    data: postData,
  });
};
export const signinAPI = (data) => {
  if ("mobile" in data)
    data.mobile = data.mobile.startsWith("0")
      ? data.mobile.substring(1)
      : data.mobile;
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "login",
    data: postData,
  });
};
export const verifyAPI = (data) => {
  if ("mobile" in data)
    data.mobile = data.mobile.startsWith("0")
      ? data.mobile.substring(1)
      : data.mobile;
  let postData = data;
  return axiosMiddleware({
    method: "post",
    url: "verify",
    data: postData,
  });
};
export const forgotEmailAPI = ({ email }) => {
  let postData = {
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-email",
    data: postData,
  });
};
export const forgotOtpAPI = ({ email, otp }) => {
  let postData = {
    email,
    token: otp,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-token",
    data: postData,
  });
};
export const forgotPasswordAPI = ({
  email,
  otp,
  newPassword,
  confirmNewPassword,
}) => {
  let postData = {
    email,
    token: otp,
    new_password: newPassword,
    confirm_new_password: confirmNewPassword,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-password",
    data: postData,
  });
};
export const logoutAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "logout",
  });
};
export const createUIdLinkAPI = () => {
  let postData = {
    return_url: "https://panel.yekpay.com/kyc",
  };
  return axiosMiddleware({
    method: "post",
    url: "uid",
    data: postData,
  });
};
export const getUserProfileAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "user/get-profile",
  });
};
export const sendMobileOtpAPI = ({ mobile }) => {
  let postData = {
    mobile,
  };
  return axiosMiddleware({
    method: "post",
    url: "send-token",
    data: postData,
  });
};
